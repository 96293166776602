import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vue-slider-marks"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_slider_mark = _resolveComponent("vue-slider-mark")!
  const _component_vue_slider_dot = _resolveComponent("vue-slider-dot")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    ref: "container",
    class: _ctx.containerClasses,
    style: _ctx.containerStyles,
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickHandle && _ctx.clickHandle(...args))),
    onTouchstartPassive: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragStartOnProcess && _ctx.dragStartOnProcess(...args))),
    onMousedownPassive: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.dragStartOnProcess && _ctx.dragStartOnProcess(...args)))
  }, _ctx.$attrs), [
    _createElementVNode("div", {
      class: "vue-slider-rail",
      style: _normalizeStyle(_ctx.railStyle)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processArray, (item, index) => {
        return _renderSlot(_ctx.$slots, "process", _normalizeProps(_guardReactiveProps(item)), () => [
          (_openBlock(), _createElementBlock("div", {
            class: "vue-slider-process",
            key: `process-${index}`,
            style: _normalizeStyle(item.style)
          }, null, 4))
        ])
      }), 256)),
      (_ctx.sliderMarks && _ctx.control)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.control.markList, (mark, index) => {
              return _renderSlot(_ctx.$slots, "mark", _normalizeProps(_guardReactiveProps(mark)), () => [
                (_openBlock(), _createBlock(_component_vue_slider_mark, {
                  key: `mark-${index}`,
                  mark: mark,
                  hideLabel: _ctx.hideLabel,
                  style: _normalizeStyle({
              [_ctx.isHorizontal ? 'height' : 'width']: '100%',
              [_ctx.isHorizontal ? 'width' : 'height']: _ctx.tailSize,
              [_ctx.mainDirection]: `${mark.pos}%`,
            }),
                  stepStyle: _ctx.stepStyle,
                  stepActiveStyle: _ctx.stepActiveStyle,
                  labelStyle: _ctx.labelStyle,
                  labelActiveStyle: _ctx.labelActiveStyle,
                  onPressLabel: (pos) => _ctx.clickable && _ctx.setValueByPos(pos)
                }, {
                  step: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "step", _normalizeProps(_guardReactiveProps(mark)))
                  ]),
                  label: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "label", _normalizeProps(_guardReactiveProps(mark)))
                  ]),
                  _: 2
                }, 1032, ["mark", "hideLabel", "style", "stepStyle", "stepActiveStyle", "labelStyle", "labelActiveStyle", "onPressLabel"]))
              ])
            }), 256))
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dots, (dot, index) => {
        return (_openBlock(), _createBlock(_component_vue_slider_dot, _mergeProps({
          ref_for: true,
          ref: `dot-${index}`,
          key: `dot-${index}`,
          value: dot.value,
          disabled: dot.disabled,
          focus: dot.focus,
          "dot-style": [
          dot.style,
          dot.disabled ? dot.disabledStyle : null,
          dot.focus ? dot.focusStyle : null,
        ],
          tooltip: dot.tooltip || _ctx.tooltip,
          "tooltip-style": [
          _ctx.tooltipStyle,
          dot.tooltipStyle,
          dot.disabled ? dot.tooltipDisabledStyle : null,
          dot.focus ? dot.tooltipFocusStyle : null,
        ],
          "tooltip-formatter": 
          Array.isArray(_ctx.sliderTooltipFormatter)
            ? _ctx.sliderTooltipFormatter[index]
            : _ctx.sliderTooltipFormatter
        ,
          "tooltip-placement": _ctx.tooltipDirections[index],
          style: [
          _ctx.dotBaseStyle,
          {
            [_ctx.mainDirection]: `${dot.pos}%`,
            transition: `${_ctx.mainDirection} ${_ctx.animateTime}s`,
          },
        ],
          onDragStart: () => _ctx.dragStart(index),
          role: "slider",
          "aria-valuenow": dot.value,
          "aria-valuemin": _ctx.min,
          "aria-valuemax": _ctx.max,
          "aria-orientation": _ctx.isHorizontal ? 'horizontal' : 'vertical',
          tabindex: "0",
          onFocus: () => _ctx.focus(dot, index),
          onBlur: _cache[0] || (_cache[0] = () => _ctx.blur())
        }, _ctx.dotAttrs), {
          dot: _withCtx(() => [
            _renderSlot(_ctx.$slots, "dot", _normalizeProps(_guardReactiveProps(dot)))
          ]),
          tooltip: _withCtx(() => [
            _renderSlot(_ctx.$slots, "tooltip", _normalizeProps(_guardReactiveProps(dot)))
          ]),
          _: 2
        }, 1040, ["value", "disabled", "focus", "dot-style", "tooltip", "tooltip-style", "tooltip-formatter", "tooltip-placement", "style", "onDragStart", "aria-valuenow", "aria-valuemin", "aria-valuemax", "aria-orientation", "onFocus"]))
      }), 128))
    ], 4),
    _renderSlot(_ctx.$slots, "default", {
      value: _ctx.getValue()
    })
  ], 16))
}